<template>
  <div class="empty-result-container">

    <v-icon size="100">
      mdi-folder-information-outline
    </v-icon>
    <h3 class="mb-4">
      موردی برای نمایش وجود ندارد.
    </h3>
  </div>
</template>

<script>
export default {
  name: "EmptyResult"
}
</script>

<style scoped>
.empty-result-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 260px);
  width: 100%;
}
</style>