<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="$emit('click')"
          class="ml-2"
          fab
          small
          color="primary"
          elevation="0"
      >
        <v-icon size="20">
          mdi-file-document
        </v-icon>
      </v-btn>
    </template>
    <span>
        مشاهده
      </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ViewButton"
}
</script>

<style scoped>

</style>