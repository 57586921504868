<template>
  <v-dialog
      width="500"
      :value="message.modal" persistent>
    <v-card>
      <v-card-title class="mb-4">
        <v-icon class="ml-2">
          mdi-message-bulleted
        </v-icon>
        ارسال پیام به کاربر
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col :cols='6'>
            <v-text-field
                label="َشماره موبایل کاربر"
                hide-details
                outlined
                v-model="message.phoneNumber"
            />
          </v-col>
          <v-col :cols='6'>
            <v-text-field
                label="عنوان پیام"
                hide-details
                outlined
                v-model="message.title"
            />
          </v-col>
          <v-col :cols='12'>
            <v-textarea
                label="توضیحات"
                hide-details
                outlined
                v-model="message.body"
            />
          </v-col>
          <v-col :cols='12'>
            <v-switch
                inset
                hide-details
                outlined
                v-model="message.withsms"
                label="به همراه ارسال پیامک"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-space-between">
        <v-btn
            @click="onClose"
            color="main" text large>
          لغو
        </v-btn>
        <v-btn
            class="white--text"
            :disabled="message.loading || !checkButton"
            :loading="message.loading"
            @click="submit"
            color="main" large>
          ارسال پیام
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {submit} from "@Newfiling/module-message/src/api";

export default {
  name: "index",

  props: {
    messageData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      message: {
        modal: false,
        loading: false,
        phoneNumber: "",
        title: "",
        body: "",
        withsms: false,
      }
    }
  },

  mounted() {
    this.message = {
      ...this.message,
      ...this.messageData,
    }
  },

  computed: {
    checkButton() {
      return Boolean(this.message.phoneNumber && this.message.body);
    }
  },

  methods: {
    async submit() {
      this.message.loading = true;
      try {
        await submit({
          "MobileRecipient": '0'+this.message.phoneNumber,
          "Title": this.message.title,
          "Messagetext": this.message.body,
          "Withsms": this.message.withsms,
        });
        this.$emit('onSubmit');
        this.$toast.success('پیام شما با موفقیت ارسال شد.');
        this.onClose();
      } catch (e) {
        console.log(e)
      }
      this.message.loading = false;
    },
    onClose() {
      this.message = {
        ...this.message,
        modal: false,
        loading: false,
        phoneNumber: "",
        title: "",
        body: "",
        withsms: false,
      }
    },
  },
  watch: {
    messageData(value) {
      this.message = {
        ...this.message,
        ...value,
      }
    }
  }
}
</script>

<style scoped>

</style>