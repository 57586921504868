<template>
    <v-pagination

            @input="onChangePage"
            v-model="page"
            class="my-2"
            v-if="totalItems"
            :length="getPages"
            :total-visible="7"
            circle
    />
</template>

<script>
    export default {

        name: "Pagination",

        props: {
            totalItems: {
                type: Number,
                default: 0,
            },
            perPage: {
                type: Number,
                required: true,
            },
            value: {
                type: Number,
                default: 0
            }

        },

        data() {
            return {
                page: 1,
            }
        },

        mounted() {
            this.page = this.value+1;
        },

        computed: {
            getPages() {
                return Math.ceil(this.totalItems / this.perPage);
            }
        },

        watch: {
            value() {
                this.page = this.value+1;
            }
        },
        methods: {
            onChangePage() {
                this.$emit('input', this.page - 1);
                this.$emit('change', this.page - 1);
            }
        }
    }
</script>

<style scoped>

</style>