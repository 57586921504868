<template>
  <v-card
      elevation="0"
      class="table-container mt-4"
  >

    <v-card-title class="table-title-container">
      <div>
        <v-sheet
            class="table-title-icon"
            width="100"
            height="100"
            rounded
            elevation="5"
            color="main">
          <v-icon :size="45" color="white">
            {{ icon }}
          </v-icon>
        </v-sheet>
        <div class="table-title">
          {{ label }}
        </div>

      </div>

      <slot name="header"/>
    </v-card-title>

    <div
        class="d-flex align-center justify-center pa-16"
        v-if="table.loading">
      <v-progress-circular
          indeterminate
          color="main"
      />
    </div>
    <v-simple-table v-if="table.items.length !== 0  && !table.loading">
      <template v-slot:default>
        <thead>
        <tr>
          <th v-for="(th,index) in table.headers"
              :key="index"
              class="text-center table-header">
            {{ th }}
          </th>
        </tr>
        </thead>

        <tbody class="table-body" v-if="table.items.length && !table.loading">
        <slot/>
        </tbody>


      </template>

    </v-simple-table>
    <EmptyResult v-if="table.items.length ===0  && !table.loading"/>
  </v-card>
</template>

<script>

import EmptyResult from "./EmptyResult";

export default {
  name: "DesktopTable",
  components: {EmptyResult},
  props: {
    table: {
      type: Object,
      default: () => {
        return {};
      }
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  }
}
</script>

<style>
.table-header {
  font-size: 12px !important;
  font-weight: bold !important;
}

.table-body td {
  font-size: 13px !important;
}

.table-title-container .table-title {
  position: relative;
  top: -25px;
  font-size: 25px;
  margin-right: 15px;
}

.table-title-container .table-title-icon {
  position: relative;
  top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-body tr td {
  font-size: 16px;
}

.table-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

@media only screen and (max-width: 768px) {

  .table-title-container .table-title-icon {
    display: none !important;
  }

  .table-title {
    padding: 10px 0 !important;
    text-align: center !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 15px !important;
    width: 100%;
    position: static;
  }

}


</style>