import {httpProvider} from "@Newfiling/Services";


export const messageSent = (data) => {
    return httpProvider.post('/eAdminMessages/adminsendmessages', data);
};


export const smsSent = (data) => {
    return httpProvider.post('/AdminMessages/AdminSendMessages2', data);
};

export const inbox = (data) => {
    return httpProvider.post('/Receivedadminmessages/Adminreceivemessages', data);
};

export const submit = (data) => {
    return httpProvider.post('/AdminMessages', data);
};

export const show = (id) => {
    return httpProvider.get('/Receivedadminmessages/'+id);
};

export const destroy = (id) => {
    return httpProvider.post('/admindeletemessage/'+id,);
};

export const sendMessageToAll = (data) => {
    return httpProvider.post('/eSendmessagetoeveryone',data);
};


